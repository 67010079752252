import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { SalesOrder } from './SalesOrder'
import { ProductionRequest } from './ProductionRequest'
import { OutShipmentLineStore } from './OutShipmentLine'

export class OutShipment extends Model {
  static backendResourceName = 'out_shipment'
  static idPrefix = 'PI'
  static idColor = 'olive'
  static idIcon = 'outbox_white'

  getUrl() {
    return `/outbound/sales-order/${this.salesOrder.id}/edit?focus=pickOrders`;
  }

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  @observable id = null
  @observable released = false

  relations() {
    return {
      salesOrder: SalesOrder,
      lines: OutShipmentLineStore,
      productionRequest: ProductionRequest,
    }
  }
}

export class OutShipmentStore extends Store {
  static backendResourceName = 'out_shipment'
  Model = OutShipment

  totalQuantityForSalesOrderLine(salesOrderLine) {
    return this.models.reduce(
      (result, inShipment) =>
        result +
        inShipment.lines
          .filter((l) => l.salesOrderLine.id === salesOrderLine.id)
          .reduce((result2, line) => result2 + line.productionRequest.quantity, 0),
      0
    )
  }

  availableQuantityForSalesOrderLine(salesOrderLine) {
    const added = this.totalQuantityForSalesOrderLine(salesOrderLine)

    return salesOrderLine.quantity - added
  }
}
