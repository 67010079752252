import React, {Component} from 'react'
import { observable, computed } from 'mobx'
import PropTypes from 'prop-types'
import { FormField } from '../../store/FormField'
import { observer } from 'mobx-react'
import TargetIcon from 'component/TargetIcon'
import { Form, Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { TargetSelect } from '@code-yellow/spider'


const ButtonFormField = styled(Form.Field)`
  width: auto !important;
  flex: 0 0 auto !important;
  height: calc(2 * 0.78571429em + 1em);
  display: flex;
  align-items: center;
  > .ui.button {
    margin: 1px 0 !important;
  }
  > i.icon {
    line-height: 1;
  }
`

const RULE_OPTIONS = ['fits', 'preferred', 'capacity', 'enough_stock'].map((key) => ({
  key,
  text: t(`formField.field.storageLocation.rule.${key}.label`),
  value: key,
}))

@observer
export default class StockRuleSelect extends Component {

  static propTypes = {
    target: PropTypes.instanceOf(FormField).isRequired,
  }

  removeRule(rule){
    const { target } = this.props

    target.stockRuleset.rules.remove(rule)

    // Recompact the order of the rules
    for (let i = 0; i < target.stockRuleset.rules.models.length; i++) {
      target.stockRuleset.rules.models[i].setInput('order', i+1)
    }
  }

  renderStockRule(rule){
    return(
      <Form.Group data-test-stock-rule={rule.id} widths="equal">
        <h3 style={{ paddingTop:'0.5em' }}>{t('formField.field.storageLocation.rule.label', { number: rule.order })}</h3>
        <TargetSelect
          noLabel
          value={rule.rule}
          options={RULE_OPTIONS}
          onChange={(value) => rule.setInput('rule', value)}
        />
        <ButtonFormField>
          <Button data-test-delete-constraint
            type="button"
            icon="delete"
            onClick={() => this.removeRule(rule)}
          />
        </ButtonFormField>
      </Form.Group>
    )
  }

  render() {
    const { target } = this.props

    if (target.stockRuleset)  
    return (
      target.stockRuleset.rules.models.map((rule) => this.renderStockRule(rule))
    )
  }
}
