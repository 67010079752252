import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { StockRuleset } from './StockRuleset'

export class StockRule extends Model {
  static backendResourceName = 'stock_rule'

  @observable id = null
  @observable rule = ''
  @observable order = null

  @observable ruleset = this.relation(StockRuleset)
 
}

export class StockRuleStore extends Store {
  static backendResourceName = 'stock_rule'
  Model = StockRule
}
