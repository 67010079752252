import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { StockRuleStore } from './StockRule'

export class StockRuleset extends Model {
  static backendResourceName = 'stock_ruleset'

  @observable id = null
  @observable finalOrdering = ''
  @observable finalOrderingAscending = true

  @observable rules = this.relation(StockRuleStore)

  relations() {
    return {
      rules: StockRuleStore,
    }
  }
}

export class StockRulesetStore extends Store {
  static backendResourceName = 'stock_ruleset'
  Model = StockRuleset
}
