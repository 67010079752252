import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

import { Form } from './Form'
import { DetailStore } from './Detail'
import { DataSource } from './DataSource'
import { ArticleType } from './ArticleType'
import { Metafield } from './Metafield'
import { ScanConstraintStore } from './ScanConstraint'
import { StockRuleset } from './StockRuleset'

export const TYPE_ARTICLE_TYPE = 'article_type'
export const MUTABLE_AFTER_PERFORMANCE_TYPES = ['best_before_date', 'text']

export class FormField extends Model {
  static backendResourceName = 'form_field'

  static TYPES = [
    'text',
    'check',
    'choice',
    'article_type',
    'measure',
    'format',
    'variable',
    'image',
    'quantity',
    'bom',
    'assembly_bom',
    'storage_location',
    'metafield',
    'material_plan_material',
    'material_plan_task',
    'sub_batches',
    'best_before_date',
  ]

  static QUANTITY_UNIT_WEIGHT_UNITS = ['mg', 'g', 'kg']

  @observable id = null
  @observable label = ''
  @observable slug = null
  @observable type = 'check'
  @observable options = []
  @observable measureMin = null
  @observable measureMax = null
  @observable dataName = ''
  @observable variableName = null
  @observable valueFormat = []
  @observable articleTypeSelf = false
  @observable quantityUnitWeight = null
  @observable quantityUnitWeightUnit = 'g'
  @observable articleTypeBatchSize = false
  @observable required = true
  @observable bestBeforePeriod = null

  @observable ordering = 0

  relations() {
    return {
      form: Form,
      articleType: ArticleType,
      details: DetailStore,
      dataSource: DataSource,
      metafield: Metafield,
      scanConstraints: ScanConstraintStore,
      stockRuleset: StockRuleset,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    if (this.type !== 'choice') {
      res.options = null
    }
    if (this.type !== 'article_type') {
      res.article_type = null
      res.article_type_self = false
    }
    if (this.type !== 'measure') {
      res.measure_min = null
      res.measure_max = null
    }
    if (this.type !== 'format') {
      res.value_format = null
    }
    if (this.type !== 'variable') {
      res.variable_name = null
    }
    if (this.type !== 'quantity') {
      res.quantity_unit_weight = null
    }
    if (this.type !== 'quantity' || this.quantityUnitWeight === null) {
      res.quantity_unit_weight_unit = null
    }
    if (this.type === 'metafield') {
      res.metafield = this.metafield?.getInternalId()
      res.label = null
      res.slug = null
    } else {
      res.metafield = null
    }
    if (this.type !== 'best_before_date') {
      res.best_before_period = null
    }

    return res
  }
}

export class FormFieldStore extends Store {
  Model = FormField
  static backendResourceName = 'form_field'
}
